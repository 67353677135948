@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

//wave
@import './modules/wave/wave';

// components
@import '../components';

@import './site/index';

//aos
//@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//Google translate
div#google_translate_element{
  text-align: right;
  padding-right: 13px;
  background-color: white;
  z-index: 99999;
  position: fixed;
  width: 100%;
  top: 0px;
  height: 28px;
  border-bottom: 1px solid #ebebeb;

  div.skiptranslate.goog-te-gadget{
    div:first-child{
      display: inline;
      margin-right: 10px;
    }
  }
}

//for google translate
body:not(.cmsedit) div#root{
  margin-top: 28px;
  .hamburger_btn{
    top: 28px;
  }
  div.navBar{
    top: 72px;
    @include media-breakpoint-up(md){
      top: 84px;
    }
    @include media-breakpoint-up(lg){
      top: 84px;
    }
  }
}

div.appInfo{
  text-align: center;
}
